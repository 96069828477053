.bg-primary {
  background: $sc-primary !important;
}
.bg-warn {
  background: $sc-danger !important;
}

.txt-primary-contrast {
  color: white !important;
}

.txt-primary {
  color: $sc-primary !important;
}

.txt-secondary {
  color: $sc-secondary !important;
}
.txt-success {
  color: $sc-success !important;
}
.hint {
  @extend .txt-gray;

  font-size: 13px;
}

.txt-danger {
  color: $sc-danger !important;
}

.txt-dark {
  color: $sc-dark !important;
}

.txt-gray {
  color: gray !important;
}

.txt-warn {
  color: $sc-warn !important;
}

.txt-purple {
  color: $sc-purple !important;
}
.txt-blue {
  color: $sc-blue !important;
}
.txt-orange {
  color: orange !important;
}

.point-Updating,
.point-Reserved,
.point-UpdatingFirmware {
  @extend .txt-orange;
  font-weight: 600;
}

.point-Online,
.point-Available,
.point-Unavailable {
  @extend .txt-secondary;
  font-weight: 600;
}

.point-Occupied,
.point-Preparing,
.point-Finishing {
  @extend .txt-warn;
  font-weight: 600;
}

.point-Charging {
  @extend .txt-blue;
  font-weight: 600;
}

.point-Discharging {
  @extend .txt-blue;
  font-weight: 600;
}

.point-Suspended,
.point-SuspendedEVSE,
.point-SuspendedEV {
  @extend .txt-purple;
  font-weight: 600;
}

.point-Offline,
.point-Faulted {
  @extend .txt-danger;
  font-weight: 600;
}

.point-Uninstalled {
  @extend .txt-gray;
  font-weight: 600;
}
