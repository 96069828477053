/* App style variables */

$toolbar-height: 50px;
$card-toolbar-height: 80px;
$margin: 15px;
$font:
  Barlow,
  Arial,
  Lucida Grande,
  sans-serif;
$spinner-size: 50px;
$spinner-size-sm: 25px;
$spinner-size-text: 17px;
$body-background: #f1f1f1;

$breakpoint-mobile: 1200px;
$nav-offset: 64px;

:root {
  --nav-offset: 0;

  @media screen and (max-width: calc($breakpoint-mobile - 1px)) {
    --nav-offset: #{$nav-offset};
  }
}

$sc-primary: #2b4c3c;
$sc-primary-contrast: #ffffff;
$sc-primary-dark: #043330;
$sc-secondary: #4e7944;
$sc-secondary-contrast: #ffffff;
$sc-success: #4e7944;
$sc-success-contrast: #ffffff;
$sc-danger: #ef4453;
$sc-danger-contrast: #ffffff;
$sc-warn: #fdcc17;
$sc-warn-contrast: #000000;
$sc-purple: purple;
$sc-light: #e0e0e0;
$sc-dark: #424242;
$sc-gray: gray;

$sc-blue: #4cb1de;
$card-shadow:
  0 10px 15px -3px #bebebe6a,
  0 4px 6px -4px #bebebe6a;
$card-radius: 1rem;
$button-shadow:
  0 10px 15px -3px #bebebe6a,
  0 4px 6px -4px #bebebe6a;
$button-radius: 0.8rem;

/**
  * @ngx-datatable theme
  */
$ngx-datatable-background: #fff;
$ngx-datatable-box-shadow:
  0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
$ngx-datatable-row-odd-background: #eee;

// Colors for table body elements
$datatable-row-detail-background: #f5f5f5;
$datatable-body-cell-color: rgba(0, 0, 0, 0.87);
$datatable-group-header-background: #f5f5f5;
$datatable-group-header-border-top-color: #d9d8d9;
$datatable-group-header-border-bottom-color: #d9d8d9;

// Colors for footer elements
$datatable-footer-cell-color: rgba(0, 0, 0, 0.54);
$datatable-footer-border-top-color: rgba(0, 0, 0, 0.12);
$datatable-pager-color: rgba(0, 0, 0, 0.54);
$datatable-pager-color-hover: rgba(0, 0, 0, 0.75);
$datatable-pager-background-hover: rgba(158, 158, 158, 0.2);
$datatable-pager-disabled-color: rgba(0, 0, 0, 0.26);
$datatable-pager-disabled-background: transparent;
$datatable-pager-active-background: rgba(158, 158, 158, 0.2);

// Colors for summary row elements
$datatable-summary-row-background: #ddd;
$datatable-summary-row-background-hover: #ddd;

// Default row and cell background colors
$ngx-datatable-default-background: #fff;
$ngx-datatable-default-background-hover: #eee;
$ngx-datatable-default-background-focus: #ddd;

// Default background colors for cell selection style
$ngx-datatable-cellselection-background-hover: #eee;
$ngx-datatable-cellselection-background-focus: #ddd;

// Background and text colors for selected cell / row
$ngx-datatable-selected-active-background: #304ffe;
$ngx-datatable-selected-active-color: $datatable-body-cell-color;
$ngx-datatable-selected-active-background-hover: #193ae4;
$ngx-datatable-selected-active-color-hover: $datatable-body-cell-color;
$ngx-datatable-selected-active-background-focus: #2041ef;
$ngx-datatable-selected-active-color-focus: $datatable-body-cell-color;

// Colors for header elements
$datatable-header-cell-background: #fff;
$datatable-header-cell-color: rgba(0, 0, 0, 0.54);
$datatable-header-border-bottom-color: rgba(0, 0, 0, 0.12);
$datatable-header-resize-handle-color: #eee;
