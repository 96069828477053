@font-face {
  font-family: connectors;
  src: url('~src/assets/connectors/connectors.eot');
  src: url('~src/assets/connectors/connectors.eot?#iefix')
      format('embedded-opentype'),
    url('~src/assets/connectors/connectors.woff') format('woff'),
    url('~src/assets/connectors/connectors.ttf') format('truetype'),
    url('~src/assets/connectors/connectors.svg#connectors') format('svg');
  font-weight: normal;
  font-style: normal;
}

i[class*='con-'] {
  position: relative;
  display: inline-block;
  font-family: connectors;
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.con-cee-blue::before {
  content: '\0041';
}

.con-cee-red-3::before {
  content: '\0042';
}

.con-combo-eu::before {
  content: '\0043';
}

.con-tesla-super::before {
  content: '\0044';
}

.con-chademo::before {
  content: '\0045';
}

.con-type-2::before {
  content: '\0046';
}

.con-type-1::before {
  content: '\0047';
}

.con-schuko::before {
  content: '\0048';
}
