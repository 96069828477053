.margin {
  margin: $margin;
}

.margin-horizontal {
  margin-left: $margin !important;
  margin-right: $margin !important;
}

.margin-vertical {
  margin-top: $margin !important;
  margin-bottom: $margin !important;
}

.margin-bottom {
  margin-bottom: $margin !important;
}

.margin-top {
  margin-top: $margin !important;
}

.margin-left {
  margin-left: $margin !important;
}

.margin-right {
  margin-right: $margin !important;
}

.neg-margin-bottom {
  margin-bottom: -$margin !important;
}

.neg-margin-top {
  margin-top: -$margin !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;

  .mat-mdc-dialog-container,
  .mdc-dialog__surface {
    padding: 0 !important;
  }
}

.padding {
  padding: $margin;
}

.padding-vertical {
  padding-top: $margin !important;
  padding-bottom: $margin !important;
}

.padding-horizontal {
  padding-left: $margin !important;
  padding-right: $margin !important;
}

.padding-left {
  padding-left: $margin !important;
}

.padding-top {
  padding-top: $margin !important;
}

.padding-bottom {
  padding-bottom: $margin !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.popover-padding {
  padding-top: 15px;
  padding-bottom: 15px;
}
