.mat-chip {
  &.cdk-drag-placeholder {
    opacity: 0.4;
  }
}

.mat-mdc-standard-chip {
  &.cdk-drag {
    cursor: grab;

    .mat-mdc-chip-trailing-icon {
      cursor: inherit;
    }
  }
}
