.flex-display {
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: flexbox; /* TWEENER - IE 10 */
  display: flex; /* NEW - Chrome */
}

.flex-1 {
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-box-flex: 1; /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex: 1; /* OLD - Firefox 19- */
  width: 20%; /* For old syntax, otherwise collapses. */
  flex: 1; /* Chrome */
  flex: 1; /* IE 10 */
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}

.flex-center-center {
  @extend .flex-align-center;
  @extend .flex-justify-center;
}

.flex-wrap {
  flex-wrap: wrap; /* Safari 6.1+ */
}

.flex-inline {
  display: inline-flex;
}
