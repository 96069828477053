@import '../../style/variables';
.mat-mdc-menu-panel {
  border-radius: $button-radius;
  &.small-menu {
    min-height: 48px;

    .mat-menu-content {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    button.mat-mdc-menu-item:not(.divider) {
      height: 38px;
      line-height: 38px;

      mat-icon.mat-icon {
        font-size: 1.5em;
      }

      span {
        font-size: 14px;
      }
    }
  }

  .divider.mat-mdc-menu-item {
    border-bottom: solid 1px lightgray;
    height: 6px;
    margin-bottom: 5px;
    min-height: auto;
  }

  .mat-mdc-menu-item span.subtitle {
    color: gray;
    display: block;
  }
}
