.badge {
  @apply inline-flex h-6 items-center justify-center gap-1.5 px-3 text-center text-sm font-normal;
  width: fit-content;

  &-xs {
    @apply h-4 px-1.5 text-xs;
  }
  &-sm {
    @apply h-5 px-2 text-xs;
  }
  &-lg {
    @apply h-7 px-3.5 text-base;
  }
  &-xl {
    @apply h-8 px-4 text-lg;
  }
}

/* Base badge styles */
.badge {
  @apply rounded-lg;

  /* Base variants */
  &-neutral {
    @apply bg-neutral-100 text-neutral-700;
  }
  &-primary {
    @apply bg-sc-primary-700 text-sc-primary-100;
  }
  &-info {
    @apply bg-sc-info-600 text-sc-info-100;
  }
  &-success {
    @apply bg-sc-success-500 text-sc-success-100;
  }
  &-warning {
    @apply bg-sc-warning-500 text-sc-warning-100;
  }
  &-error {
    @apply bg-sc-error-600 text-sc-error-100;
  }

  /* Soft variants */
  &-soft {
    @apply bg-neutral-300/75;

    &.badge-neutral {
      @apply bg-neutral-100/75 text-neutral-800;
    }
    &.badge-primary {
      @apply bg-sc-primary-100/75 text-sc-primary-800;
    }
    &.badge-info {
      @apply bg-sc-info-100/75 text-sc-info-800;
    }
    &.badge-success {
      @apply bg-sc-success-100/75 text-sc-success-800;
    }
    &.badge-warning {
      @apply bg-sc-warning-100/75 text-sc-warning-800;
    }
    &.badge-error {
      @apply bg-sc-error-100/75 text-sc-error-800;
    }
  }

  /* Outline variants */
  &-outline {
    @apply border border-current border-solid bg-transparent;

    &.badge-neutral {
      @apply text-neutral-800;
    }
    &.badge-primary {
      @apply text-sc-primary-800;
    }
    &.badge-info {
      @apply text-sc-info-800;
    }
    &.badge-success {
      @apply text-sc-success-800;
    }
    &.badge-warning {
      @apply text-sc-warning-800;
    }
    &.badge-error {
      @apply text-sc-error-800;
    }
  }
}
