.my-drop-zone {
  border: dashed 2px lightgray;
  border-radius: 2px;
  height: 140px;
  width: 200px;

  &.drop-zone-100 {
    width: 100%;
  }

  display: table;
  position: relative;
  cursor: pointer;

  span {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #a7a7a7;
  }

  img {
    margin: auto;
    max-width: 200px;

    //max-height: 140px;
  }

  &.has-file {
    span {
      color: $sc-dark;
    }
  }
}

.nv-file-over {
  span {
    color: rgb(167 167 167 / 65%);
  }

  border-color: $sc-primary;
  border-style: solid;
  background-color: rgba($sc-primary, 0.1);
}
