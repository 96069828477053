@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $accent-palette: map.get($color-config, 'accent');

  .pulse {
    border: 5px solid mat.m2-get-color-from-palette($accent-palette);
    color: blue;
  }
}

@mixin typography($theme) {
  // .my-carousel {
  //   font-family: serif;
  // }
}
