.alert {
  @apply relative w-full p-4;
}

.alert {
  @apply rounded-md;

  &-neutral {
    @apply bg-neutral-100 text-neutral-700;
  }
  &-primary {
    @apply bg-sc-primary-700 text-sc-primary-100;
  }
  &-info {
    @apply bg-sc-info-600 text-sc-info-100;
  }
  &-success {
    @apply bg-sc-success-500 text-sc-success-100;
  }
  &-warning {
    @apply bg-sc-warning-500 text-sc-warning-100;
  }
  &-error {
    @apply bg-sc-error-600 text-sc-error-100;
  }

  /* Soft Variant */
  &-soft {
    &.alert-neutral {
      @apply bg-neutral-100/75 text-neutral-800;
    }
    &.alert-primary {
      @apply bg-sc-primary-100/75 text-sc-primary-800;
    }
    &.alert-info {
      @apply bg-sc-info-100/75 text-sc-info-800;
    }
    &.alert-success {
      @apply bg-sc-success-100/75 text-sc-success-800;
    }
    &.alert-warning {
      @apply bg-sc-warning-100/75 text-sc-warning-800;
    }
    &.alert-error {
      @apply bg-sc-error-100/75 text-sc-error-800;
    }
  }

  /* Outline Variant */
  &-outline {
    @apply border border-current border-solid bg-transparent;

    &.alert-neutral {
      @apply text-neutral-800;
    }
    &.alert-primary {
      @apply text-sc-primary-800;
    }
    &.alert-info {
      @apply text-sc-info-800;
    }
    &.alert-success {
      @apply text-sc-success-800;
    }
    &.alert-warning {
      @apply text-sc-warning-800;
    }
    &.alert-error {
      @apply text-sc-error-800;
    }
  }
}
