mat-row,
mat-header-row {
  .small-cell {
    max-width: 100px;
  }

  mat-cell.mat-cell,
  mat-header-cell.mat-header-cell {
    padding: 0 15px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.small-cell {
  max-width: 100px;
}

.table {
  border-collapse: collapse;
}

.ngx-datatable.material {
  .datatable-body {
    .datatable-body-row {
      .datatable-body-cell {
        color: inherit;
        display: flex;
        align-items: center;
      }
    }
  }

  .mat-mdc-checkbox {
    .mdc-checkbox {
      flex: 0;
      margin: 0;
      padding: 0;

      &__background {
        top: 0;
      }
    }
  }

  .datatable-footer {
    .datatable-footer-inner {
      container-name: footer;
      container-type: inline-size;
      flex-wrap: nowrap;
      min-height: 32px;

      @container footer (width < 500px) {
        .page-count {
          display: none !important;
        }
      }
    }

    .page-count {
      flex-basis: auto;
      text-wrap: nowrap;
    }

    .datatable-pager {
      flex-basis: auto;

      .pager {
        display: flex;
        justify-content: flex-end;

        li {
          margin: 0 1px;

          a {
            align-items: center;
            box-sizing: content-box;
            color: $sc-dark;
            display: inline-flex;
            height: 22px;
            justify-content: center;
            line-height: 22px;
            margin: 0;
            min-width: auto;
            padding: 2px;
            width: 22px;
          }
        }
      }

      i[class^='datatable-icon-'] {
        line-height: normal;
        padding: 0;

        &::before {
          vertical-align: bottom;
        }
      }
    }
  }
}

.datatable-body-row {
  &--success {
    background-color: lighten($sc-success, 55%);
    color: $sc-primary;
  }

  &--bold {
    .datatable-body-cell {
      /* It's ugly, but ngx-datatable styling is terrible made */
      font-weight: 500 !important;
    }
  }

  &--error {
    background-color: lighten(red, 43%);
  }

  &--warning {
    background-color: lighten(orange, 43%);
  }

  &--important {
    background-color: #f0ffff;
  }
}
