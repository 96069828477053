.mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination {
  height: var(--mdc-secondary-navigation-tab-container-height);
}

.mat-mdc-tab {
  --mat-tab-header-label-text-tracking: 0.07em;
}

mat-tab-header.mat-mdc-tab-header {
  padding: 0 6px;
}

.mat-mdc-tab-body-content {
  padding: 0 6px 16px;
}
