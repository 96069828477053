@import '../../style/variables';
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  padding: 0 15px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: $card-radius;
  background: white;
  box-shadow: $card-shadow;
}

.popover .arrow {
  display: none;
}

.popover.bs-popover-top,
.popover.bs-popover-auto[x-placement^='top'] {
  margin-bottom: 10px;
}

.popover.bs-popover-top .arrow,
.popover.bs-popover-auto[x-placement^='top'] .arrow {
  bottom: 0;
}

.popover.bs-popover-top .arrow::before,
.popover.bs-popover-auto[x-placement^='top'] .arrow::before,
.popover.bs-popover-top .arrow::after,
.popover.bs-popover-auto[x-placement^='top'] .arrow::after {
  border-bottom-width: 0;
}

.popover.bs-popover-top .arrow::before,
.popover.bs-popover-auto[x-placement^='top'] .arrow::before {
  bottom: -11px;
  margin-left: -6px;
  border-top-color: rgb(0 0 0 / 25%);
}

.popover.bs-popover-top .arrow::after,
.popover.bs-popover-auto[x-placement^='top'] .arrow::after {
  bottom: -10px;
  margin-left: -6px;
  border-top-color: #fff;
}

.popover.bs-popover-right,
.popover.bs-popover-auto[x-placement^='right'] {
  margin-left: 10px;
}

.popover.bs-popover-right .arrow,
.popover.bs-popover-auto[x-placement^='right'] .arrow {
  left: 0;
}

.popover.bs-popover-right .arrow::before,
.popover.bs-popover-auto[x-placement^='right'] .arrow::before,
.popover.bs-popover-right .arrow::after,
.popover.bs-popover-auto[x-placement^='right'] .arrow::after {
  margin-top: -8px;
  border-left-width: 0;
}

.popover.bs-popover-right .arrow::before,
.popover.bs-popover-auto[x-placement^='right'] .arrow::before {
  left: -11px;
  border-right-color: rgb(0 0 0 / 25%);
}

.popover.bs-popover-right .arrow::after,
.popover.bs-popover-auto[x-placement^='right'] .arrow::after {
  left: -10px;
  border-right-color: #fff;
}

.popover.bs-popover-bottom,
.popover.bs-popover-auto[x-placement^='bottom'] {
  margin-top: 10px;
}

.popover.bs-popover-bottom .arrow,
.popover.bs-popover-auto[x-placement^='bottom'] .arrow {
  top: 0;
}

.popover.bs-popover-bottom .arrow::before,
.popover.bs-popover-auto[x-placement^='bottom'] .arrow::before,
.popover.bs-popover-bottom .arrow::after,
.popover.bs-popover-auto[x-placement^='bottom'] .arrow::after {
  margin-left: -7px;
  border-top-width: 0;
}

.popover.bs-popover-bottom .arrow::before,
.popover.bs-popover-auto[x-placement^='bottom'] .arrow::before {
  top: -11px;
  border-bottom-color: rgb(0 0 0 / 25%);
}

.popover.bs-popover-bottom .arrow::after,
.popover.bs-popover-auto[x-placement^='bottom'] .arrow::after {
  top: -10px;
  border-bottom-color: #fff;
}

.popover.bs-popover-bottom .popover-header::before,
.popover.bs-popover-auto[x-placement^='bottom'] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 20px;
  margin-left: -10px;
  content: '';
  border-bottom: 1px solid #f7f7f7;
}

.popover.bs-popover-left,
.popover.bs-popover-auto[x-placement^='left'] {
  margin-right: 10px;
}

.popover.bs-popover-left .arrow,
.popover.bs-popover-auto[x-placement^='left'] .arrow {
  right: 0;
}

.popover.bs-popover-left .arrow::before,
.popover.bs-popover-auto[x-placement^='left'] .arrow::before,
.popover.bs-popover-left .arrow::after,
.popover.bs-popover-auto[x-placement^='left'] .arrow::after {
  margin-top: -8px;
  border-right-width: 0;
}

.popover.bs-popover-left .arrow::before,
.popover.bs-popover-auto[x-placement^='left'] .arrow::before {
  right: -11px;
  border-left-color: rgb(0 0 0 / 25%);
}

.popover.bs-popover-left .arrow::after,
.popover.bs-popover-auto[x-placement^='left'] .arrow::after {
  right: -10px;
  border-left-color: #fff;
}

.popover-header:empty {
  display: none;
}
