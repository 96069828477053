@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary: map.get($color-config, 'primary');
  $foreground: map.get($theme, foreground);

  .main-sidenav {
    li {
      a {
        // color: black;
        color: mat.m2-get-color-from-palette($foreground, secondary-text);

        &.active {
          color: mat.m2-get-color-from-palette($primary);

          .item-inner {
            border-color: mat.m2-get-color-from-palette($primary);
          }
        }

        &:hover {
          color: mat.m2-get-color-from-palette($primary);

          .item-inner {
            border-color: mat.m2-get-color-from-palette($primary) !important;
          }
        }
      }
    }
  }

  .menu-search-container {
    &:hover {
      .menu-search {
        .mat-form-field-underline {
          background-color: mat.m2-get-color-from-palette($primary);
        }
      }
    }
  }
}
