.mat-mdc-button-base {
  &.mat-gray {
    --mdc-filled-button-container-color: #{lighten($sc-light, 7%)};
  }
}

/** @depracated */

button.small-button,
.mat-mdc-icon-button.mat-mdc-button-base.small-button {
  --mdc-icon-button-state-layer-size: 36px;

  min-width: 1%;
  line-height: inherit;
  padding: 0;

  &.mat-icon-button {
    height: 30px;
    width: 30px;
    line-height: 1;
  }
}

.button-row button,
.button-row a {
  &:not(:last-child) {
    margin-right: 8px;
  }
}

.mat-mdc-button {
  --mdc-text-button-label-text-tracking: 0;
  .mdc-button__label {
    display: flex;
    align-items: center;
  }
}

.mat-mdc-icon-button.mat-mdc-button-base.mat-unthemed {
  --mdc-icon-button-state-layer-size: 36px;

  font-size: 1rem;
  padding: 0;

  .mdc-icon-button__ripple {
    display: none;
  }
}

button.no-click {
  cursor: default;
}
button,
a {
  &.mat-mdc-raised-button {
    border-radius: $button-radius;
    box-shadow: $button-shadow !important;
  }
}

.mat-mdc-raised-button {
  .mdc-button__label {
    align-items: center;
    display: inline-flex;

    .mat-icon {
      margin-right: 8px;
    }
  }
}
