@use 'sass:map';
@use '@angular/material' as mat;

@use '../app/components/main-sidenav/main-sidenav.component-theme' as sidenav;
@use '../app/components/single-point/single-point.component-theme' as
  singlepoint;

@import './variables';
// Plus imports for other components in your app.

//GENERATOR: http://mcg.mbitson.com/

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies;
@include mat.core();

$primary-palette: (
  50: #e6eae8,
  100: #bfc9c5,
  200: #95a69e,
  300: #6b8277,
  400: #4b6759,
  500: #2b4c3c,
  600: #264536,
  700: #203c2e,
  800: #1a3327,
  900: #10241a,
  A100: #65ffad,
  A200: #32ff92,
  A400: #00fe77,
  A700: #00e56b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);
$danger-palette: (
  50: #fde9ea,
  100: #fac7cb,
  200: #f7a2a9,
  300: #f47c87,
  400: #f1606d,
  500: #ef4453,
  600: #ed3e4c,
  700: #eb3542,
  800: #e82d39,
  900: #e41f29,
  A100: #ffffff,
  A200: #ffe7e8,
  A400: #ffb4b7,
  A700: #ff9b9f,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);
$accent-palette: (
  50: #fff9e3,
  100: #fef0b9,
  200: #fee68b,
  300: #fedb5d,
  400: #fdd43a,
  500: #fdcc17,
  600: #fdc714,
  700: #fcc011,
  800: #fcb90d,
  900: #fcad07,
  A100: #ffffff,
  A200: #fffaf1,
  A400: #ffe8be,
  A700: #ffdfa4,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$primary: mat.m2-define-palette($primary-palette, 700);

$accent: mat.m2-define-palette($accent-palette, 700);

// The warn palette is optional (defaults to red).
$warn: mat.m2-define-palette($danger-palette);

// Create the theme object (a Sass map containing all of the palettes).
$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn
    ),
    typography: mat.m2-define-typography-config(),
    density: 0
  )
);
$foreground: map-get($theme, foreground);

.mat-form-field {
  &.mat-focused {
    &.mat-primary .mat-select-arrow,
    &.mat-primary .mat-form-field-underline {
      color: mat.m2-get-color-from-palette($primary);
    }

    &.mat-accent .mat-select-arrow,
    &.mat-accent .mat-form-field-underline {
      color: mat.m2-get-color-from-palette($accent);
    }

    &.mat-warn .mat-select-arrow,
    &.mat-warn .mat-form-field-underline {
      color: mat.m2-get-color-from-palette($warn);
    }
  }
}

.error {
  color: mat.m2-get-color-from-palette($warn);
  font-size: 12px;
}

a {
  text-decoration: none;
  color: $sc-blue;
  cursor: pointer;

  &:hover {
    color: darken($sc-blue, 15%);
  }
}

mat-table.hover-table {
  mat-row {
    &:hover {
      background: rgba(mat.m2-get-color-from-palette($primary), 0.1);
      cursor: pointer;
    }
  }
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);

@include sidenav.color($theme);
@include singlepoint.color($theme);
