.default-toolbar {
}

/* Toolbar */
mat-toolbar.mat-toolbar {
  mat-slide-toggle.mat-slide-toggle {
    font-weight: 400;
    font-size: 14px;
  }

  &.main-toolbar {
    //height: $toolbar-height !important;
    min-height: $toolbar-height !important;

    mat-toolbar-row.mat-toolbar-row {
      height: $toolbar-height;
    }
  }

  &.card-toolbar {
    @extend .main-toolbar;

    background: transparent;
    min-height: $card-toolbar-height !important;

    mat-toolbar-row.mat-toolbar-row {
      height: $card-toolbar-height;
      padding-left: 0;
    }

    padding-left: 24px;
    padding-right: 24px;
  }

  &.conf-toolbar {
    @extend .main-toolbar;

    background: transparent;
    padding: 0;
  }

  &.page-toolbar {
    @extend .main-toolbar;

    background: transparent;
    font: inherit;
  }

  .toolbar-filler {
    flex: 1 1 auto;
  }

  .toolbar-center {
    width: 100%;
    text-align: center;
    left: 0;
    font-size: 17px;
    position: absolute;
  }

  .toolbar-small-text {
    font-size: 0.7em;
    font-weight: 400;
    color: gray;
  }

  .toolbar-title {
    margin-right: 20px;
    font-weight: 400;
  }

  mat-form-field {
    margin-right: 10px;
  }

  button.toolbar-button,
  a.toolbar-button {
    background: transparent;
    border: 0;
    box-shadow: none;
    color: $sc-primary;
  }
}
